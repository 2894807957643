
import { encryptDes, decryptDes } from '@/assets/utils/des.js';

class ChangeLocal {
    setItem = (name, value) => {
        value = encryptDes(JSON.stringify(value));
        localStorage.setItem(name, value);
    };
    getItem = (name) => {
        try {
            let val = localStorage.getItem(name);
            if (val == null || val == '') return null;
            return JSON.parse(decryptDes(val));
        } catch (e) {
            localStorage.removeItem(name);
            return null;
        }
    };
}

export default ChangeLocal;