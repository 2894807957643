import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import '@/css/reset.css'
import axios from 'axios';

import store from './store'
import i18n from './lang'

import Vant from 'vant';
import { Lazyload } from 'vant';
import 'vant/lib/index.css';

import ChangeCookie from './assets/utils/change_cookie';
Vue.prototype.$changeCookie = new ChangeCookie() //全局注册

import ChangeLocal from './assets/utils/change_local';
Vue.prototype.$changeLocal = new ChangeLocal() //全局注册

import '@/assets/font/font.css';

Vue.use(Vant)
Vue.use(Lazyload)

Vue.config.productionTip = false
Vue.prototype.$axios = axios //全局注册，使用方法为:this.$axios

new Vue({
    i18n,
    router, store,
    render: h => h(App),
}).$mount('#app')