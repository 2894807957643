import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    isApp: true,
    countdownTimer: null,
    countdownCount: 0,
    randomTimer: null,
    randomCount: '00',
    gmtOffset: '',
    assetsUrl: '',
    signToken: '',
    token: '',
}

const mutations = {
    setIsApp(state, tag) {
        state.isApp = tag
    },
    // 设置静态文件地址
    setAssetsUrl(state, url) {
        state.assetsUrl = url;
    },
    // 设置token
    setGmtOffset(state, gmtOffset) {
        state.gmtOffset = gmtOffset;
    },
    // 设置token
    setToken(state, token) {
        state.token = token;
    },
    // 设置注册模块token
    setSignToken(state, token) {
        state.signToken = token;
    },
    // 设置倒计时
    setCountdownCount(state, count) {
        state.countdownCount = count;
    },
    // 设计倒计时的定时器
    setCountdownCountTimer(state, timer) {
        state.countdownTimer = timer;
    },
    // 
    setRandomCount(state, count) {
        state.randomCount = count;
    },
    setRandomTimer(state, timer) {
        state.randomTimer = timer;
    },
}

const actions = {
    setCountdownCount({ commit, state }, count) {
        clearInterval(state.countdownTimer);
        clearInterval(state.randomTimer);
        commit('setCountdownCount', count);
        let countdownCount = state.countdownCount - 1;
        commit('setCountdownCount', countdownCount);
        localStorage.setItem('countdownCount', countdownCount);
        let countdownTimer = setInterval(() => {
            let countdownCount = state.countdownCount - 1;
            commit('setCountdownCount', countdownCount);
            localStorage.setItem('countdownCount', countdownCount);
            if (countdownCount <= 0) {
                clearInterval(state.countdownTimer);
                clearInterval(state.randomTimer);
            }
        }, 1000)

        commit('setCountdownCountTimer', countdownTimer);

        let randomTimer = setInterval(() => {
            let random = Math.floor(Math.random() * 100);
            let randomCount = random < 10 ? '0' + random : random;
            commit('setRandomCount', randomCount);
        }, 100)
        commit('setRandomTimer', randomTimer);
    }
}

const getters = {
    minute: state => {
        return Math.floor(state.countdownCount / 60) < 0 ? '0' + Math.floor(state.countdownCount / 60) : Math.floor(state.countdownCount / 60);
    },
    second: state => {
        return state.countdownCount % 60 < 10 ? '0' + state.countdownCount % 60 : state.countdownCount % 60;
    }
}

export default new Vuex.Store({
    state, mutations, actions, getters
})