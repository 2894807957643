<template>
  <div id="app">
    <Header id="app_header" v-if="!$store.state.isApp" class="Header"></Header>
    <router-view id="app_router" class="router-view" :class="$store.state.isApp ? 'app_top' : ''"> </router-view>
    <Footer id="app_footer" v-if="!$store.state.isApp"></Footer>
    <!-- 用于测试注册模块的数据清理 -->
    <!-- <div class="clear_info" v-if="showClear">
      <van-popover placement="top-end" v-model="showPopover" trigger="click" :actions="actions" @select="onSelect">
        <template #reference>
          <van-button type="primary">clear</van-button>
        </template>
      </van-popover>
    </div> -->
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      showClear: false,
      showPopover: false,
      actions: [{ text: 'Login token', type: 'loginToken' }, { text: 'Register token', type: 'registerToken' }],
    }
  },
  created() { this.initData() },
  mounted() {
    if (process.env.NODE_ENV == 'development') {
      this.showClear = true;
    }
  },
  methods: {
    onSelect(action) {
      if (process.env.NODE_ENV != 'development') return;
      this.$changeCookie.clearCookie(action.type);
      if (action.type == 'loginToken') {
        this.$store.commit('setToken', '');
      } else if (action.type == 'registerToken') {
        this.$store.commit('setSignToken', '');
      }
    },
    initData() {
      // 设置静态文件地址
      this.$store.commit('setAssetsUrl', process.env.VUE_APP_ASSETS_URL);
      let time = new Date();
      let gmtOffset = (time.getTimezoneOffset() / -60) * 60 * 60;
      this.$store.commit('setGmtOffset', gmtOffset);
      this.getToken();
    },
    getToken() {
      let registerToken = this.$changeCookie.getCookie('registerToken');
      if (registerToken) {
        this.$store.commit('setSignToken', registerToken);
      }
      let token = this.$changeCookie.getCookie('loginToken');
      if (token) {
        this.$store.commit('setToken', token);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "@/css/less_custom.less";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;

  .Header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  .router-view {
    margin-top: 100px;
  }

  .clear_info {
    position: fixed;
    right: 10px;
    bottom: 10px;
    background-color: #fff;
    z-index: 99;
  }
}

@media screen and (max-width: @MobileMaxWidth) {
  #app {

    .router-view {
      margin-top: 44px;
    }
  }
}

.app_top {
  margin-top: 0px !important;
}
</style>
