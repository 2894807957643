<template>
  <div class="footer_container">
    <div class="footer-links">
      <div class="footer-img-links">
        <a :href="item.link" v-for="(item, ind) in imgLinkList" :key="ind">
          <img :src="item.url">
        </a>
      </div>
      <div class="footer-txt-links">
        <span v-for="(item, ind) in txtLinkList" @click="goLink(item.url)" :key="ind">{{ item.txt }}</span>
      </div>
    </div>
    <div class="footer-copy">
      © 2022 Lighter Life LTD. All Rights Reserved
    </div>
    <!-- <div class="social">
      <a href="https://www.tiktok.com/@litely_app" target="_blank">
        <img class="dy" src="@/assets/img/Tiktok.webp" alt="" />
      </a>
      <a href="https://twitter.com/Litely_app" target="_blank">
        <img class="tw" src="@/assets/img/Twitter.webp" alt="" />
      </a>
      <a href="https://www.facebook.com/groups/intermittent.fasting.tracker.community.litely" target="_blank">
        <img class="fb" src="@/assets/img/FB.webp" alt="" />
      </a>
      <a href="https://www.instagram.com/litelyfastingapp/" target="_blank">
        <img class="ins" src="@/assets/img/Ins.webp" alt="" />
      </a>
    </div> -->

    <!-- <div class="privacy_terms">
      <div class="privacy" @click="privacy_clicked">Privacy Policy</div>
      <div class="line"></div>
      <div class="terms" @click="terms_clicked">Terms of Use</div>
    </div>

    <div class="rights">© 2022 Lighter Life LTD. All Rights Reserved</div> -->

  </div>
</template>

<script>
export default {
  data() {
    return {
      txtLinkList: [
        { txt: 'Privacy Policy', url: '/privacy/web' },
        { txt: 'Terms of Use', url: '/terms/web' },
        { txt: 'Disclaimer', url: '/disclaimer/web' },
        { txt: 'Contact Us', url: '/contact' },
        { txt: 'About Us', url: '/about' },
      ],
      imgLinkList: [
        { url: require('@/assets/img/tiktok.png'), link: 'https://www.tiktok.com/@litely_app' },
        { url: require('@/assets/img/instagram.png'), link: 'https://www.instagram.com/accounts/login/?next=/litelyfastingapp/' },
        { url: require('@/assets/img/twitter.png'), link: 'https://twitter.com/Litely_app' },
        { url: require('@/assets/img/facebook.png'), link: 'https://www.facebook.com/groups/intermittent.fasting.tracker.community.litely' },
      ]
    };
  },
  mounted() { },
  components: {},
  computed: {},
  methods: {
    goLink(url) {
      if (url && url.length > 0) {
        this.$router.push(url)
      }
    },
    // privacy_clicked() {
    //   this.$router.push("/privacy/web");
    // },
    // terms_clicked() {
    //   this.$router.push("/terms/web");
    // },
  },
};
</script>
<style lang='less' scoped>
@import "@/css/less_custom.less";

.footer_container {
  width: 100%;
  padding: 79px 0 31px;
  box-sizing: border-box;
  background: linear-gradient(to right, #ffa164, #ff646b);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #fff;

  .footer-links {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .footer-txt-links {
      display: flex;
      align-items: center;
      font-size: 14px;

      span {
        padding: 0 18px;
        position: relative;
        cursor: pointer;

        &::after {
          content: '';
          right: 0;
          top: 50%;
          width: 1px;
          height: 20px;
          transform: translate(0, -50%);
          background-color: #ddd;
          position: absolute;
        }
      }

      span:nth-last-child(1) {
        &::after {
          display: none;
        }
      }

    }

    .footer-img-links {
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translate(0%, -50%);
      display: flex;
      margin-left: 35px;

      a {

        margin-right: 24px;
      }

      img {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }

      img:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }

  .footer-copy {
    margin-top: 26px;
  }

  // .social {
  //   margin-top: 83px;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   width: 275px;

  //   img {
  //     width: 35px;
  //     height: 38px;
  //   }
  // }

  // @media screen and (max-width: @MobileMaxWidth) {
  //   .social {
  //     margin-top: 53px;
  //     width: 205px;

  //     img {
  //       width: 25px;
  //       height: 28px;
  //     }
  //   }
  // }

  // .privacy_terms {
  //   display: flex;
  //   justify-content: space-between;
  //   margin-top: 40px;
  //   margin-bottom: 40px;
  //   align-items: center;
  //   width: 220px;

  //   .line {
  //     height: 12px;
  //     width: 1px;
  //     background-color: rgba(255, 255, 255, 1);
  //   }

  //   .privacy,
  //   .terms {
  //     font-size: 14px;
  //     font-weight: 400;
  //     color: rgba(255, 255, 255, 1);
  //     cursor: pointer;
  //   }
  // }

  // @media screen and (max-width: @MobileMaxWidth) {
  //   .privacy_terms {
  //     margin-top: 30px;
  //     margin-bottom: 30px;
  //   }
  // }

  // .rights {
  //   font-size: 18px;
  //   font-weight: 500;
  //   color: rgba(255, 255, 255, 1);
  // }

  // @media screen and (max-width: @MobileMaxWidth) {
  //   .rights {
  //     font-size: 14px;
  //   }
  // }
}

@media screen and (max-width: @MiddleMaxWidth) {
  .footer_container {
    padding: 32px 0 16px;

    .footer-links {
      .footer-txt-links {
        font-size: 8px;

        span {
          padding: 0 6px;
        }
      }

      .footer-img-links {
        position: static;
        transform: translate(0, 0);
        margin-left: 0;
        display: block;
        margin-bottom: 15px;

        img {
          width: 22px;
          height: 22px;
          margin-right: 12px;
        }
      }

    }

    .footer-copy {
      font-size: 10px;
      margin-top: 18px;
    }
  }
}

@media screen and (max-width: @MobileMaxWidth) {
  .footer_container {
    // height: 220px;
  }
}
</style>