import cryptoJs from "crypto-js";


/*
 * message:需要解密的字符串，
 * key： 密钥（加密解密密钥同一个）
 */
//DES加密
const encryptDes = (message, key = "litely2023") => {
    var keyHex = cryptoJs.enc.Utf8.parse(key);
    var option = { mode: cryptoJs.mode.ECB, padding: cryptoJs.pad.Pkcs7 };
    var encrypted = cryptoJs.DES.encrypt(message, keyHex, option);
    return encrypted.toString();
};

//DES解密
const decryptDes = (message, key = "litely2023") => {
    var keyHex = cryptoJs.enc.Utf8.parse(key);
    var decrypted = cryptoJs.DES.decrypt(
        message,
        keyHex,
        {
            mode: cryptoJs.mode.ECB,
            padding: cryptoJs.pad.Pkcs7
        }
    );
    return decrypted.toString(cryptoJs.enc.Utf8);
};

export { encryptDes, decryptDes };