import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// 引入本地包
const messages = {
    en: require('./en.json'),
}

// 创建国际化实例
const i18n = new VueI18n({
    locale: window.sessionStorage.getItem('lang') || 'en',
    messages
})

export default i18n;