
import { encryptDes, decryptDes } from '@/assets/utils/des.js';

class ChangeCookie {
    //设置cookie
    setCookie = function (name, value, days) {
        // 过期时间为天数转为毫秒数-2小时转成毫秒数
        let time = (days * 24 * 60 * 60 * 1000 - 2 * 60 * 60 * 1000);
        value = encryptDes(value);
        var d = new Date();
        d.setTime(d.getTime() + time);
        var expires = "expires=" + d.toUTCString();
        document.cookie = name + "=" + value + "; " + expires;
    };
    //获取cookie
    getCookie = function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1);
            if (c.indexOf(name) != -1) {
                return decryptDes(c.substring(name.length, c.length));
            }
        }
        return "";
    };
    //清除cookie
    clearCookie = function (name) {
        this.setCookie(name, "", -1);
    };
}

export default ChangeCookie;